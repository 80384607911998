import $ from 'jquery';

export default class UploadImage {
  upload_file(file){
    const vm = this;
    return new Promise(function(resolve, reject){
      if( file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg"){
        if( file.size > 2200000 ){
          return resolve({error:"File too big: max size is 2 MB"});
        } else{
          return vm.getSignedRequest(file, resolve);
        }
      } else if(file.type === "video/mp4") {
        if( file.size > 22000000 ) {
          return resolve({error:"File too big: max size is 20 MB"});
        } else {
          return vm.getSignedRequest(file, resolve);
        }
      } else {
        return resolve({error: "Wrong file type"});
      }
    })
  }

  getSignedRequest(file, resolve){
    const fileObject = { file : { type : file.type, size : file.size } };
    return $.ajax({
      url: "https://nodeimageuploader.herokuapp.com/image",
      data : JSON.stringify(fileObject),
      contentType: "application/json; charset=utf-8",
      dataType: 'json',
      cache: false,
      method : 'POST',
      success: function(data) {
        return this.uploadFile( file, data.data.requestUrl, data.data.imageUrl, resolve);
      }.bind(this),
      error: function(xhr, status, err) {
        return resolve({error:'Error on getting signed request : ' + err});
      }
    });
  };

  uploadFile(file,signed_request,response_url, resolve){
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signed_request);
    xhr.setRequestHeader("Cache-Control", "public,max-age=3600");
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.onload = function() {
      if (xhr.status === 200) {
        console.log("file uploaded successfully");
        return resolve({imageUrl: response_url});
      }
    }.bind(this);
    xhr.onerror = function() {
      return resolve({error: "Unknown Error, re-upload and try again"});
    }.bind(this);
    xhr.send(file);
  };
}

