import decode from 'jwt-decode';

export default class AuthService {
    // Initializing important variables
    // 'http://localhost:5000/api/'
    constructor(domain) {
        var urlString = "https://dev-scratchers.sqwadapp.co/api/"
        if(process.env.NODE_ENV === "production" && window.location.hostname === 'games-admin.sqwadapp.co'){
          urlString = "https://prod-scratchers.sqwadapp.co/api/"
        }
        // urlString = "https://prod-scratchers.sqwadapp.co/api/"
        // urlString = 'http://localhost:5000/api/'
        this.domain = domain || urlString
        // this.domain = domain || 'http://192.168.1.20:45455/api/' // API server domain
        // this.domain = 'http://localhost:5000/api/'
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
        this.setToken = this.setToken.bind(this)
        this.getToken = this.getToken.bind(this)
    }

    login(email, password) {
        // Get a token from api server using the fetch api
        var vm = this;
        var teamLocation = window.location.pathname.split('/')[1] || ""
        var tenantId = localStorage.getItem('TenantId'+teamLocation)
        var newObject = {"userName": email, "password": password, "tenantId": tenantId};

        return this.fetch(`${this.domain}Auth/Login`, {
          method: 'POST',
          headers: {
  	         'Content-Type': 'application/json',
           },
          body: JSON.stringify(newObject),
        }).then(res => {
          if(res.authToken){
            vm.setToken(res.authToken);
          }
          return Promise.resolve(res);
        }).catch(err =>{
          return Promise.resolve(err);
        })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to localStorage
        var teamLocation = window.location.pathname.split('/')[1] || ""
        localStorage.setItem('id_token'+teamLocation, idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        var teamLocation = window.location.pathname.split('/')[1] || ""
        return localStorage.getItem('id_token'+teamLocation)
    }

    logout() {
        // Clear user token and profile data from localStorage
        var teamLocation = window.location.pathname.split('/')[1] || ""
        localStorage.removeItem('id_token'+teamLocation);
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

    isAdmin() {
      const token = this.getToken();
      if(token){
        if(decode(token).rol === "api_access"){
          return true;
        }
      }
      return false;
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
            if(options.headers){
              options.headers['Authorization'] = 'Bearer ' + this.getToken()
              options.headers['Accept'] = 'application/json'
            }
        }

        return fetch(url, {
            headers,
            ...options
        }).then( response => {
            return response.json()
          }
        ).catch(
          error => console.error('Error:', error)
        )
            // .then(response => response.json())
    }
}
