import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { base, appDatabasePrimaryFunctions } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import AuthService from '../utils/AuthService';
import { Accordion, AccordionItem } from 'react-sanfona';

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.state = {
          ticketList: [],
          tenantVariables: {},
          questionsList: [],
          questionsAdded: [],
          emailsSent: {},
          question: 0,
          emailVariables: {},
          tenantRules: {},
          id_key: "",
          userAnswers:[],
          currentGameRewards: [],
          csvRewards: [],
          currentGameFormFilledList: {},
          currentTriviaGame: null,
          modal: false,
          timeLeft: 10,
          timeModal: false,
          userGameHistory: [],
          users: [],
          gameName: "",
          loading: true,
          wizardLocation: 'first',
          showStats: false,
          tierMessaging: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.timeModalToggle = this.timeModalToggle.bind(this);
    }

    componentDidMount() {
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
      });
      this.idKeyRef = base.fetch(`id_key`, {
        context: this,
        then(data){
          this.setState({
            id_key: data
          })
        }
      });
      this.emailVariablesRef = base.bindToState(`emailVariables`, {
        context: this,
        state: 'emailVariables',
      });
      this.emailSentRef = base.bindToState(`emailsSent`, {
        context: this,
        state: 'emailsSent',
      });
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      this.rewardsListRef = base.syncState(`rewardsList`, {
        context: this,
        state: 'ticketList',
        asArray: true
      });
      this.tierMessagingRef = base.syncState(`tierMessaging`, {
        context: this,
        state: 'tierMessaging',
        asArray: true
      });
      this.currentGameFormFilledRef = base.bindToState('currentGameFormFilled', {
        context: this,
        state: 'currentGameFormFilledList',
      });
      this.usersRef = base.bindToState('users', {
        context: this,
        state: 'users',
      });
      this.questionsListRef = base.bindToState('questionsList',{
        context: this,
        state: 'questionsList',
        asArray: true
      });
      this.userAnswersRef = base.syncState('userAnswers',{
        context: this,
        state: 'userAnswers',
        asArray: true
      })
      this.currentTriviaGameRef = base.syncState(`currentTriviaGame`, {
        context: this,
        state: 'currentTriviaGame',
        then(err){
          if(!err){
            this.setGameToWatch(this.state.currentTriviaGame.id);
            this.listenToChangesInQuestion()
          }
        }
      });
      this.setState({loading:false})
    }

    listenToChangesInQuestion(){
      this.currentTriviaGameQuestionsRef = base.listenTo(`currentTriviaGame/questions`, {
        context: this,
        then(currentGameQuestionList){
          var allQuestionsShown = true
          for(var questionIndex in currentGameQuestionList){
            var question = currentGameQuestionList[questionIndex];
            if(!question.shown){
              allQuestionsShown = false
            }
          }
          if(allQuestionsShown && currentGameQuestionList.length > 0){
            this.distributeMessaging();
          }
        }
      });
    }

    setGameToWatch(id){
      if(this.userGameHistoryRef){
        base.removeBinding(this.userGameHistoryRef);
      }
      if(id){
        this.userGameHistoryRef = base.bindToState('userGameHistory',{
          context: this,
          state: 'userGameHistory',
          asArray: true,
          queries: {
            orderByChild: id,
            equalTo: id,
          }
        })
      }
    }

    downloadUsers(){
      this.setState({loading: true})
      let csv = 'Email,Number of Correct Answers,Reward,Code,Answer Time\n';
      let answerList = this.sortPlayersForAdmin(this.state.currentTriviaGame,this.state.users,this.state.userAnswers);
      const vm = this;
      answerList.forEach(function(row) {
        csv += row.email;
        csv+= ",";
        csv+= row.correctAnswers;
        csv+= ",";
        csv+= (row.rewardSent || "NONE");
        csv+= ",";
        csv+= (row.code || "-");
        csv+= ",";
        csv += vm.convertSecondsTimeStampToHumanTime(row.timestamp/1000);
        csv += "\n";
      });
      let hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      let date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv"
      hiddenElement.download = "users_" + date_got;
      hiddenElement.click();
      this.setState({loading: false})
    }

    componentWillUnmount() {
      base.removeBinding(this.userGameHistoryRef);
      base.removeBinding(this.tenantVariablesRef);
      base.removeBinding(this.currentTriviaGameQuestionsRef);
      base.removeBinding(this.rewardsListRef);
      base.removeBinding(this.questionsListRef);
      base.removeBinding(this.currentTriviaGameRef);
      base.removeBinding(this.userAnswersRef);
      base.removeBinding(this.usersRef);
      base.removeBinding(this.emailSentRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.emailVariablesRef);
    }

    hide_create_game_buttons(){
      var elements = document.getElementsByClassName("create-game-modal");
      for (var i = 0; i < elements.length; i++){
        // elements[i].style.display = 'none';
        elements[i].disabled = true;
      }
    }

    findReward(rewardId){
      for(var rewardIndex in this.state.ticketList){
        var reward = this.state.ticketList[rewardIndex]
        if(reward.key === rewardId){
          return Object.assign({}, reward);
        }
      }
    }

    distributeMessaging(){
      const users = this.state.users;
      var userAnswers = this.state.userAnswers;
      var selectedGame = this.state.currentTriviaGame;
      var rewardsList = selectedGame.rewards;
      var cmp = (a, b) => (a > b) - (a < b)
      rewardsList.sort(function(a, b) {
        return cmp(b.number_correct,a.number_correct) || cmp(a.priority,b.priority)
      })
      var prizeWon = {}
      for(var userAnswerIndex in userAnswers){
        var userAnswer = userAnswers[userAnswerIndex];
        prizeWon[userAnswer.key] = false
      }
      var currentUserAnswersState = this.sortPlayersForAdmin(selectedGame,users,userAnswers) || []
      var emails_in_array = [];
      var keepRotatingIndex = 0;
      for(const rewardIndex in rewardsList){
        const reward = rewardsList[rewardIndex];
        const reci_reward = {};
        reci_reward.reward = Object.assign({}, reward);
        const threshold = reci_reward.reward.number_correct;
        let keepRotating = true;
        let rewardAmountLeftToGive = reci_reward.reward.amount;
        if(rewardAmountLeftToGive === "Remaning Winners"){
          rewardAmountLeftToGive = currentUserAnswersState.length
        }
        while(keepRotating){
          const currentAnswer = currentUserAnswersState[keepRotatingIndex];
          if(currentAnswer && parseInt(threshold) === parseInt(currentAnswer.correctAnswers) && rewardAmountLeftToGive > 0){
            if(emails_in_array.indexOf(currentAnswer.email) === -1){
              emails_in_array.push(currentAnswer.email);
              prizeWon[btoa(currentAnswer.email)] = true
            }
            rewardAmountLeftToGive--;
            keepRotatingIndex++;
          } else if (currentAnswer && parseInt(threshold) < parseInt(currentAnswer.correctAnswers)) {
            keepRotatingIndex++;
          } else {
            keepRotating = false;
          }
        }
      }
      base.post(`prizeWon`, {
        data: prizeWon,
        then(err){
          if(!err){
            console.log("success")
          }
        }
      })
    }

    createGame(){
      var createGameObject = {};
      var tenantVariables = this.state.tenantVariables;
      var gameId = appDatabasePrimaryFunctions.ref().push().key;
      var questionsAdded = this.state.questionsAdded;
      var gameName = this.state.gameName.trim();
      if(!gameName){
        swal({
            title: "Hold On!",
            text: 'The game needs a name!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return
      }
      var rewardList = []
      var messaging_array = []
      var rewards_added = false
      for(var i = 0; i < questionsAdded.length + 1; i++){
        var index = i;
        var tiermessaging = {}
        tiermessaging.number_correct = index;
        var winningHeader = "You win!";
        var winningMessage = "Check your email for your prize!";
        var winningMissedHeader = "Nice Job!";
        var winningMissedMessage = "You got " + index.toString() + " correct! Unfortunately you didn't win a prize but try again soon!"
        var losingHeader = "Oh No!"
        var losingMessage = "Nice try but no prize this time!  Try again soon!"
        if(index > 0){
          winningMessage = "You got " + index.toString() + " correct! Check your email for your prize!"
        }
        if(this.state['winningWinnerHeader' + index.toString()] != undefined){
          winningHeader = this.state['winningWinnerHeader' + index.toString()]
        } else if (tenantVariables.defaultWinningHeader && tenantVariables.defaultWinningHeader.length > 0) {
          winningHeader = this.detectUseOfIndex(tenantVariables.defaultWinningHeader, index)
        }
        if(this.state['winningWinnerMessage' + index.toString()] != undefined){
          winningMessage = this.state['winningWinnerMessage' + index.toString()]
        } else if (tenantVariables.defaultWinningMessage && tenantVariables.defaultWinningMessage.length > 0) {
          winningMessage = this.detectUseOfIndex(tenantVariables.defaultWinningMessage, index)
        }
        if(this.state['winningMissedHeader' + index.toString()] != undefined){
          winningMissedHeader = this.state['winningMissedHeader' + index.toString()]
        } else if (tenantVariables.defaultWinningMissHeader && tenantVariables.defaultWinningMissHeader.length > 0) {
          winningMissedHeader = this.detectUseOfIndex(tenantVariables.defaultWinningMissHeader, index)
        }
        if(this.state['winningMissedMessage' + index.toString()] != undefined){
          winningMissedMessage = this.state['winningMissedMessage' + index.toString()]
        } else if (tenantVariables.defaultWinningMissMessage && tenantVariables.defaultWinningMissMessage.length > 0) {
          winningMissedMessage = this.detectUseOfIndex(tenantVariables.defaultWinningMissMessage, index)
        }
        if(this.state['losingHeader' + i.toString()] != undefined){
          losingHeader = this.state['losingHeader' + i.toString()]
        } else if (tenantVariables.defaultLosingHeader && tenantVariables.defaultLosingHeader.length > 0) {
          losingHeader = tenantVariables.defaultLosingHeader
        }
        if(this.state['losingMessage' + i.toString()] != undefined){
          losingMessage = this.state['losingMessage' + i.toString()]
        } else if (tenantVariables.defaultLosingMessage && tenantVariables.defaultLosingMessage.length > 0) {
          losingMessage = tenantVariables.defaultLosingMessage
        }

        tiermessaging.winningHeader = winningHeader;
        tiermessaging.winningMessage = winningMessage;
        tiermessaging.winningMissedHeader = winningMissedHeader;
        tiermessaging.winningMissedMessage = winningMissedMessage;
        tiermessaging.losingHeader = losingHeader;
        tiermessaging.losingMessage = losingMessage;

        var main_reward = this.state['ticket_select' + i.toString()];
        var rewardAmount = this.state['rewardAmount' + i.toString()];
        var codes = this.state['codes' + i.toString()];
        var codesArray = this.state['codesArray' + i.toString()];
        var ticket_select_all_else = this.state['ticket_select_all_else' + i.toString()];
        main_reward = this.findReward(main_reward);
        ticket_select_all_else = this.findReward(ticket_select_all_else);
        if(main_reward){
          if(rewardAmount){
            main_reward.amount = rewardAmount;
            main_reward.priority = 0
          } else {
            swal({
                title: "Hold On!",
                text: 'Problem With Correct Answers ' + i.toString() + ': All main rewards must have an amount to give out, otherwise please make it an "All Else" reward',
                type: 'warning',
                confirmButtonText: 'Ok'
              });
            return
          }
          main_reward.codes = codes || false
          if(codes){
            codesArray = this.detectHowManyCodesEntered(codesArray);
            if(codesArray.length > 0 && codesArray.length === parseInt(rewardAmount)){
              main_reward.codesArray = codesArray
            } else {
              swal({
                  title: "Hold On!",
                  text: 'Problem With Correct Answers ' + i.toString() + ': The number of codes does not equal the amount of rewards added',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
              return
            }
          }
          main_reward.number_correct = i;
          rewardList.push(main_reward)
        }
        messaging_array.push(tiermessaging)
        if(ticket_select_all_else){
          ticket_select_all_else.number_correct = i;
          ticket_select_all_else.amount = "Remaning Winners";
          ticket_select_all_else.priority = 1;
          rewardList.push(ticket_select_all_else)
        }

        if(ticket_select_all_else || main_reward){
          rewards_added = true
        }
      }
      if(questionsAdded.length === 0){
        swal({
            title: "Missing Question",
            text: 'Go to the first tab to add a question',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      if(!rewards_added){
        swal({
            title: "Hold On!",
            text: 'You have no rewards added to the game! You need at least 1',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      createGameObject.questions = questionsAdded;
      createGameObject.active = false;
      createGameObject.answered = false;
      createGameObject.rotate = false;
      createGameObject.rotationEnd = false;
      createGameObject.screenToShow = 0;
      createGameObject.questionToShow = 0;
      createGameObject.rewards = rewardList;
      createGameObject.timeStamp = Date.now();
      createGameObject.id = gameId;
      createGameObject.gameName = gameName;
      var currentGame = this.state.currentTriviaGame;
      currentGame['users'] = this.state.userGameHistory.length;
      currentGame['answers'] = this.state.userAnswers.length;
      currentGame['tier_messages'] = this.state.tierMessaging || ""
      var vm = this;
      base.push('gamesList', {
        data: currentGame,
        then(err){
          if(!err){
            vm.setState({
              currentTriviaGame: createGameObject,
              modal: false,
              userAnswers: [],
              tierMessaging: messaging_array
            }, ()=>{
              for(var i = 0; i < questionsAdded.length + 1; i++){
                vm.setState({
                  ['ticket_select' + i.toString()]: null,
                  ['rewardAmount' + i.toString()]: null,
                  ['codes' + i.toString()]: null,
                  ['codesArray' + i.toString()]: null,
                  ['ticket_select_all_else' + i.toString()]: null,
                  ['winningWinnerHeader' + i.toString()]: null,
                  ['winningWinnerMessage' + i.toString()]: null,
                  ['winningMissedHeader' + i.toString()]: null,
                  ['winningMissedMessage' + i.toString()]: null,
                  ['losingHeader' + i.toString()]: null,
                  ['losingMessage' + i.toString()]: null
                })
              }
              vm.setState({
                questionsAdded:[],
              })

              base.post(`emailsSent`, {
                data: null,
                then(err){
                  if(!err){
                    console.log("success")
                  }
                }
              })
              base.post(`prizeWon`, {
                data: null,
                then(err){
                  if(!err){
                    console.log("success")
                  }
                }
              })
            })
            vm.setGameToWatch(gameId);
          }
        }
      })
    }

    detectHowManyCodesEntered(codesArray){
    let splitOnLineBreaks = codesArray.split("\n");
    let splitOnWhiteSpace = codesArray.split(/\s+/);
    let splitOnCommas = codesArray.split(",");
    let splitArray = splitOnLineBreaks;
    if(splitArray.length < splitOnWhiteSpace.length){
      splitArray = splitOnWhiteSpace
    }
    if(splitArray.length < splitOnCommas.length){
      splitArray = splitOnCommas
    }
    return splitArray
  }

    stopGame(){
      var currentGame = this.state.currentTriviaGame;
      currentGame.active = false;
      this.setState({
        currentTriviaGame: currentGame
      })
      swal({
        title: 'Game Stopped',
        type: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: 10000
      })
    }

    startGame() {
      var currentGame = this.state.currentTriviaGame;
      currentGame.timeStamp = Date.now()
      currentGame.active = true;
      this.setState({
        currentTriviaGame: currentGame
      })
      swal({
          title: 'Game Started',
          type: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: true,
          timer: 10000
        })
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    endGame(){
      const emailVariables = this.state.emailVariables;
      if(!this.validateEmail(emailVariables.sender)){
        swal({
            title: "Hold On!",
            text: 'You have need to update the email sender in the Email Branding section first!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      const subject = emailVariables.subjectLine;
      const email_help_text = emailVariables.helpText;
      const header_image = emailVariables.emailBackgroundImage;
      if(!subject || !email_help_text || !header_image){
        swal({
            title: "Hold On!",
            text: 'Please make sure email variables are entered!',
            type: 'warning',
            confirmButtonText: 'Ok'
          });
        return;
      }
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will start displaying the answers!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES I WANT TO DISPLAY ANSWERS',
        }).then((result)=>{
          if(result.value){
            this.setState({
              timeModal: true,
            })
            this.startRotation();
            swal({
              title: 'Answer Showing Started',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
          }
      })
    }

    startRotation(){
      this.setTimeouts()
    }

  cancelCountDown(){
      if(this.state.questionTimeOutId){
        clearTimeout(this.state.questionTimeOutId);
      }
      if(this.state.AnswersTimeOutId){
        clearTimeout(this.state.AnswersTimeOutId);
      }
      if(this.state.AnswerTimeOutId){
        clearTimeout(this.state.AnswerTimeOutId);
      }
      if(this.state.questionCountDownId){
        clearInterval(this.state.questionCountDownId);
      }
      if(this.state.answersCountDownId){
        clearInterval(this.state.answersCountDownId);
      }
      if(this.state.answerCountDownId){
        clearInterval(this.state.answerCountDownId);
      }
      const currentGame = this.state.currentTriviaGame;
      currentGame.screenToShow = 0;
      currentGame.questionToShow = 0;
      currentGame.rotationEnd = false;
      currentGame.rotate = false;
      for(const questionIndex in currentGame.questions){
        const question = currentGame.questions[questionIndex];
        question.shown = false;
      }

      this.setState({
        currentTriviaGame: currentGame,
        question: 0,
        timeModal: false,
      })
    }

    setTimeouts(){
      var tenantVariables = this.state.tenantVariables;
      var currentGame = this.state.currentTriviaGame;
      var secondsBetweenQuestions = tenantVariables.questionDisplayLength || "5";
      var secondsBetweenQuestionsConverted = parseInt(secondsBetweenQuestions + "000", 10)
      secondsBetweenQuestions = parseInt(secondsBetweenQuestions, 10)
      var questionNumber = this.state.question;
      var questionOn = (parseInt(questionNumber) + 1).toString();
      currentGame.screenToShow = 0
      currentGame.rotate = true
      currentGame.questionToShow = this.state.question
      this.setState({
        currentTriviaGame: currentGame,
        timeLeft: secondsBetweenQuestions,
        timerStatus: "Showing Question " + questionOn,
      })
      var vm = this;
      var questionCountDown = setInterval(function(){
        secondsBetweenQuestions = secondsBetweenQuestions - 1
        vm.setState({
          timeLeft: secondsBetweenQuestions,
          timerStatus: "Showing Question " + questionOn
        })
      }, 1000)
      var waitForQuestionTimeout = setTimeout(function (){
        clearInterval(questionCountDown);
        vm.setAnswerTimer()
      }, secondsBetweenQuestionsConverted)
      this.setState({
        questionTimeOutId: waitForQuestionTimeout,
        questionCountDownId: questionCountDown
      })
    }

    setAnswerTimer(){
    var tenantVariables = this.state.tenantVariables;
    var currentGame = this.state.currentTriviaGame
    var secondsShowingAnswer = tenantVariables.answerDisplayLength || "5";
    var secondsShowingAnswerConverted = parseInt(secondsShowingAnswer + "000", 10)
    secondsShowingAnswer = parseInt(secondsShowingAnswer, 10)
    currentGame.screenToShow = 2
    currentGame.questions[this.state.question].shown = true
    this.setState({
      timeLeft: secondsShowingAnswer,
      timerStatus: "Showing Correct Answer",
      currentTriviaGame: currentGame
    })
    var vm = this;
    var answerCountDown = setInterval(function(){
      secondsShowingAnswer = secondsShowingAnswer - 1
      vm.setState({
        timeLeft: secondsShowingAnswer
      })
    }, 1000)
    var waitForAnswerTimeout = setTimeout(function (){
      clearInterval(answerCountDown);
      if(vm.state.question < vm.state.currentTriviaGame.questions.length - 1){
        var question = parseInt(vm.state.question, 10) + 1
        vm.setState({
          question: question
        }, () => {
          vm.setTimeouts()
        })
      } else {
        currentGame.rotationEnd = true;
        vm.setState({
          timeLeft: 0,
          timeModal: false,
          currentTriviaGame: currentGame
        }, () => {
          vm.calculateWinners();
        })
      }
    }, secondsShowingAnswerConverted)
    this.setState({
      AnswerTimeOutId: waitForAnswerTimeout,
      answerCountDownId: answerCountDown
    })
  }

    calculateWinners(){
    const currentGame = this.state.currentTriviaGame;
    const users = this.state.users;
    const questions = currentGame.questions;
    const correctAnswers = [];
    for(let questionIndex in questions){
      let answers = questions[questionIndex].answers;
      for(let answerIndex in answers){
        if(answers[answerIndex].correct){
          correctAnswers.push(answers[answerIndex].id)
        }
      }
    }
    const userAnswers = this.state.userAnswers;
    const usersAnswersAttempted = []
    for(let userAnswerIndex in userAnswers){
      let user = {}
      const answers = userAnswers[userAnswerIndex];
      const timeStamp = userAnswers[userAnswerIndex].timeStamp;
      let correctAnswersCount = 0;
      for(let answerIndex in answers){
        if(correctAnswers.indexOf(answers[answerIndex]) > -1){
          correctAnswersCount++;
        }
      }
      user['timestamp'] = timeStamp;
      user['correctAnswers'] = correctAnswersCount;
      if(users[answers.key]){
        user['email'] = users[answers.key].email;
      } else {
        continue;
      }
      usersAnswersAttempted.push(user)
    }
    const sortedRewards = currentGame.rewards;
    let cmp = (a, b) => (a > b) - (a < b);
    usersAnswersAttempted.sort(function(a, b) {
      return cmp(b.correctAnswers,a.correctAnswers) || cmp(a.timestamp,b.timestamp)
    });
    sortedRewards.sort(function(a, b) {
      return cmp(b.number_correct,a.number_correct) || cmp(a.priority,b.priority)
    });
    this.sendRewardsToPlayers(sortedRewards, usersAnswersAttempted, currentGame.id)
  }

    sendRewardsToPlayers(rewards, currentGameuserAnswersArray, currentGameId){
    if(currentGameuserAnswersArray.length === 0){
      swal({
        title: 'No Winners!',
        type: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: 10000
      })
      return;
    }
    var emails_in_array = [];
    var recipients_with_prizes = [];
    var keepRotatingIndex = 0;
    for(var rewardIndex in rewards){
      var reward = rewards[rewardIndex];
      var reci_reward = {};
      reci_reward.reward = Object.assign({}, reward);
      var recipients = [];
      var threshold = reci_reward.reward.number_correct;
      var keepRotating = true;
      var rewardAmountLeftToGive = reci_reward.reward.amount
      if(rewardAmountLeftToGive === "Remaning Winners"){
        rewardAmountLeftToGive = currentGameuserAnswersArray.length
      }
      while(keepRotating){
        var currentAnswer = currentGameuserAnswersArray[keepRotatingIndex];
        if(currentAnswer && parseInt(threshold) === parseInt(currentAnswer.correctAnswers) && rewardAmountLeftToGive > 0){
          if(emails_in_array.indexOf(currentAnswer.email) === -1){
            recipients.push(currentAnswer.email)
            emails_in_array.push(currentAnswer.email)
          }
          rewardAmountLeftToGive--;
          keepRotatingIndex++;
        } else if (currentAnswer && parseInt(threshold) < parseInt(currentAnswer.correctAnswers)) {
          keepRotatingIndex++;
        } else {
          keepRotating = false;
        }
      }
      reci_reward.recipients = recipients
      recipients_with_prizes.push(reci_reward)
    }

    for(var recipients_with_prize_index in recipients_with_prizes){
      var recipients_with_prize = recipients_with_prizes[recipients_with_prize_index]
      if(recipients_with_prize.recipients.length === 0){
        console.log("No Prizes Skipping")
        continue;
      }
      this.sendWinningEmail(recipients_with_prize.recipients, recipients_with_prize.reward, currentGameId)
    }
  }

    sendWinningEmail(recipients_list, reward, currentGameId){
    var emailVariables = this.state.emailVariables;
    var rulesAndRegsVars = this.state.tenantRules;
    var from_who = emailVariables.sender;
    var subject = emailVariables.subjectLine;
    var email_help_text = emailVariables.helpText;
    var header_image = emailVariables.emailBackgroundImage;
    var team_logo = emailVariables.teamIconImage;
    var reward_link = reward.rewardLink;
    var description = reward.description;
    var codes = reward.codesArray;
    var reward_name = reward.rewardName;
    var reward_image = reward.emailImage;
    var level_text = reward.number_correct_message;
    var rules_regs_link = rulesAndRegsVars.rulesAndRegsLink;
    var gameObject = {"from_who": from_who, "subject": subject, "recipients": recipients_list,
    "reward_link": reward_link, "header_image": header_image, "description": description,
    "codes": codes, "reward_name": reward_name, "reward_image": reward_image, "level_text":level_text,
    "email_help_text": email_help_text, "rules_regs_link": rules_regs_link, "team_logo": team_logo};

    this.setState({
      loading:true
    })
    this.Auth.fetch('https://still-fjord-64720.herokuapp.com/rewards/levels', {
    // this.Auth.fetch('http://localhost:5000/rewards/levels', {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         'IDToken': this.state.id_key
       },
      body: JSON.stringify(gameObject)
    }).then(response => {
      if(!response){
        swal({
          title: 'Something went wrong!  Check your internet connection and try again!',
          type: 'error',
          toast: true,
          position: 'top-end',
          showConfirmButton: true,
          timer: 10000
        })
        this.setState({
          loading: false
        })
        return
      }
      if(response.message === "Emails sent!"){
        swal({
          title: 'Emails Sent!',
          type: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: true,
          timer: 10000
        })
        var gameToSave = {}
        for(var recipeient_index in response.recipients){
          var rewardToSave = {}
          rewardToSave.name = reward_name
          var codeSent = false
          if(response.codes){
            codeSent = response.codes[recipeient_index]
            rewardToSave.code = codeSent
          }
          var email = response.recipients[recipeient_index]
          var baseEncodedEmail = btoa(email)
          gameToSave[baseEncodedEmail] = rewardToSave
        }
        base.update(`emailsSent`, {
          data: gameToSave,
          then(err){
            if(!err){
              console.log("success")
            }
          }
        })
      } else {
        console.log("ERROR")
        console.log(response)
      }
      this.setState({
        loading: false
      })
    })
  }

    handleChange (evt) {
      var target = evt.target;
      var value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first",
        question: 0
      });
    }

    timeModalToggle(){
      this.setState({
        timeModal: !this.state.timeModal
      })
    }

    resetGame(){
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will erase all information gathered during this game! Only do this if no one has played yet.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES I WANT TO RESET THE GAME',
        }).then((result)=>{
          if(result.value){
            const currentGameState = this.state.currentTriviaGame;
            currentGameState.active = false;
            currentGameState.answered = false;
            currentGameState.rotationEnd = false;
            currentGameState.startRotation = false;
            const history = this.state.userGameHistory;
            for(const questionIndex in currentGameState.questions){
               currentGameState.questions[questionIndex].shown = false
            }
            for(const historyRef in history){
              const refInstance = history[historyRef];
              base.post(`userGameHistory/${refInstance.key}/${currentGameState.id}`, {
                data: null,
                then(err){
                  if(!err){
                    console.log("success")
                  }
                }
              })
              base.post(`currentGameFormFilled/${currentGameState.id}`, {
                data: null,
                then(err){
                  if(!err){
                    console.log("success")
                  }
                }
              })
            }
            base.post(`emailsSent`, {
              data: null,
              then(err){
                if(!err){
                  console.log("success")
                }
              }
            });
            base.post(`prizeWon`, {
              data: null,
              then(err){
                if(!err){
                  console.log("success")
                }
              }
            });
            this.cancelCountDown();
            this.setState({
              userAnswers: [],
              currentTriviaGame: currentGameState,
              question: 0
            })
          }
        })
    }

    navButtonClicked(direction){
      var currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        })
        this.toggleWizardLocation('third', 'first', 'second')
      } else {
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2){
      this.setState({
        wizardLocation: tabClicked
      })
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
    }

    switchStatsPrizes(){
      if(this.state.showStats){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showStats').classList.add('active');
        this.setState({
          showStats:false,
        })
      }else{
        document.getElementById('showPrizes').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        this.setState({
          showStats:true,
        })
      }
    }

    alertAboutLoading(){
      swal({
          title: 'Rewards Still Loading',
          text: 'We are setting the data up for download, please wait',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
    }

    detectUseOfIndex(text, index){
      text = text.replace(/%index%/g, index)
      return text
    }

    removeFromToQuestionAddArray(index){
      var answerArray = this.state.questionsAdded;
      answerArray.splice(index, 1)
      this.setState({
        questionsAdded: answerArray
      })
    }

    addQuestionToLocalArray(){
      let selectedQuestion = null;
      const questionsAddedArray = this.state.questionsAdded;
      const questionList = this.state.questionsList;
      for(const questionAddedIndex in questionsAddedArray){
        if(questionsAddedArray[questionAddedIndex].key === this.state.question_select){
          swal({
              title: "Question Already In Game!",
              text: "This game already includes this question.  Try adding another question.",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          return;
        }
      }
      for(const questionListIndex in this.state.questionsList){
        if(questionList[questionListIndex].key === this.state.question_select){
          selectedQuestion = questionList[questionListIndex];
          selectedQuestion['id'] = questionList[questionListIndex].key;
        }
      }
      if(selectedQuestion){
         questionsAddedArray.push(selectedQuestion);
         this.setState({
           questionsAdded: questionsAddedArray
         })
      }
    }

    convertSecondsTimeStampToHumanTime(dateInSeconds){
      var tzoffset = (new Date()).getTimezoneOffset() * 60;
      var afterTimezoneChangeSeconds = dateInSeconds - tzoffset
      return this.toDateTime(afterTimezoneChangeSeconds).toDateString() + " " + this.toStandardTime(new Date(this.toDateTime(afterTimezoneChangeSeconds).getTime()).toString().split(" ")[4])
    }

    toDateTime(secs) {
      const t = new Date(1970, 0, 1); // Epoch
      t.setSeconds(secs);
      return t;
    }

    toStandardTime(value) {
      if (value !== null && value !== undefined){ //If value is passed in
        if(value.indexOf('AM') > -1 || value.indexOf('PM') > -1){ //If time is already in standard time then don't format.
          return value;
        }
        else {
          if(value.length == 8){ //If value is the expected length for military time then process to standard time.
            var hour = value.substring ( 0,2 ); //Extract hour
            var minutes = value.substring ( 3,5 ); //Extract minutes
            var identifier = 'AM'; //Initialize AM PM identifier
     
            if(hour == 12){ //If hour is 12 then should set AM PM identifier to PM
              identifier = 'PM';
            }
            if(hour == 0){ //If hour is 0 then set to 12 for standard time 12 AM
              hour=12;
            }
            if(hour > 12){ //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
              hour = hour - 12;
              identifier='PM';
            }
            return hour + ':' + minutes + ' ' + identifier; //Return the constructed standard time
          }
          else { //If value is not the expected length than just return the value as is
            return value;
          }
        }
      }
    }

    sortPlayersForAdmin(currentGame,users,userAnswers){
      const questions = currentGame.questions;
      const correctAnswers = [];
      for(let questionIndex in questions){
        let answers = questions[questionIndex].answers;
        for(let answerIndex in answers){
          if(answers[answerIndex].correct){
            correctAnswers.push(answers[answerIndex].id)
          }
        }
      }
      const usersAnswersAttempted = [];
      for(let userAnswerIndex in userAnswers){
        const user = {}
        const answers = userAnswers[userAnswerIndex];
        let rewardSent = this.state.emailsSent[answers.key] || false;
        let code = "";
        if(rewardSent){
          code = rewardSent.code;
          rewardSent = rewardSent.name;
        }
        const timeStamp = userAnswers[userAnswerIndex].timeStamp;
        let correctAnswersCount = 0;
        for(const answerIndex in answers){
          if(correctAnswers.indexOf(answers[answerIndex]) > -1){
            correctAnswersCount++;
          }
        }
        user['timestamp'] = timeStamp;
        user['correctAnswers'] = correctAnswersCount;
        if(users[answers.key]){
          user['email'] = users[answers.key].email;
        } else {
          continue;
        }

        user['rewardSent'] = rewardSent;
        user['code'] = code;
        usersAnswersAttempted.push(user)
      }
      const cmp = (a, b) => (a > b) - (a < b);
      usersAnswersAttempted.sort(function(a, b) {
        return cmp(b.correctAnswers,a.correctAnswers) || cmp(a.timestamp,b.timestamp)
      });
      return usersAnswersAttempted
    }

    render() {
      let selectedGame = this.state.currentTriviaGame || null;
      let selectedGameQuestions = [];
      let selectedGameRewards = [];
      // const list = this.state.currentGameFormFilledList;
      const users = this.state.users;
      const tenantVariables = this.state.tenantVariables;
      const players = this.state.userGameHistory || [];
      const players_length = players.length;
      let gameActive = false;
      const userAnswers = this.state.userAnswers;
      let currentUserAnswersState = [];
      let hideEndGameButton = false;
      let questionsAddedLength = [];
      for (let i = -1; i < this.state.questionsAdded.length; i++) {
        questionsAddedLength.push(i);
      }
      let gameName = "";
      if(selectedGame && selectedGame.id){
        selectedGameQuestions = selectedGame.questions || [];
        selectedGameRewards = selectedGame.rewards || [];
        gameActive = selectedGame.active;
        gameName = selectedGame.gameName;
        currentUserAnswersState = this.sortPlayersForAdmin(selectedGame,users,userAnswers);
        if(selectedGame.id && selectedGame.answered){
          hideEndGameButton = true
        }
      } else if(selectedGame && !selectedGame.id){
        selectedGame = null;
      }
      const vm = this;
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-2" style={{display: selectedGame && selectedGame.answered ? '' : 'none'}}>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px'}}>
                    <p style={{lineHeight:'50px'}}>Game Ended</p>
                  </div>
                </div>
                <div className="col-md-2" style={{display: selectedGame && !selectedGame.answered ? '' : 'none'}}>
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Is Active</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? 'none' : '', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game is Inactive</p>
                  </div>
                </div>
                <div className="col-md-2" style={{display: selectedGame && !selectedGame.answered ? '' : 'none'}}>
                  <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: gameActive ? 'none' : '', float:'left', height:'52px'}}>Start Game</button>
                  <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: gameActive ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                </div>
                <div className="col-md-2">
                  <button onClick={() => this.endGame()} className="btn btn-primary btn-lg end-game-button" style={{fontSize: "1rem", display: selectedGame && !hideEndGameButton ? '' : 'none', float:'left', height:'52px' }}>Start Scoreboard</button>
                </div>
                <div className="col-md-6">
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetGame()} style={{display: selectedGame && !selectedGame.key ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                  <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" width="50px" style={{float:'right', marginRight:'5px'}}>New Game</button>
                </div>
              </div>
            </div>
            <center style={{display: !selectedGame ? 'none': 'block'}}>
              <h3 style={{color:'black', margin:'15px'}}>Game Set Up -- {gameName}</h3>
              <div className="row">
                <div className="col-md-6">
                  <h4 style={{color:'black'}}>Question</h4>
                  {
                    selectedGameQuestions.map(function(item, i){
                      return <div key={i}>
                        <p style={{color:'black'}}>{item.questionText}</p>
                        <ol style={{textAlign:'left'}}>
                          {
                            item.answers.map(function(answer,i) {
                              if(answer.correct){
                                return <li key={i} style={{color:'black'}}>{answer.answerText} -- Selected Answer</li>
                              } else {
                                return <li key={i} style={{color:'black'}}>{answer.answerText}</li>
                              }
                            })
                          }
                        </ol>
                      </div>
                    })
                  }
                </div>
                <div className="col-md-6">
                  <h4 style={{color:'black'}}>Rewards</h4>
                  <ul style={{textAlign:'left'}}>
                  {
                    selectedGameRewards.map(function(item, i){
                      return <li style={{color:'black'}} key={i}>
                        <span><u>{item.rewardName}</u></span>
                        <br/>
                        <span>Amount: {item.amount}</span>
                        <br/>
                        <span>Number Correct To Earn: {item.number_correct}</span>
                        <br/>
                        <br/>
                      </li>
                    })
                  }
                  </ul>
                </div>
              </div>
            </center>
            <div className="" style={{display:selectedGame?'':'none'}}>
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Stats</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Answers</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                  <div className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}>
                    <span className="fa fa-arrow-circle-down"/> Download Participants
                  </div>
                  <div style={{height: '10px', width: '100%'}}/>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>Email</th>
                        <th>Number Correct</th>
                        <th>Time Stamp</th>
                        <th>Place</th>
                        <th>Reward Sent</th>
                        <th>Code</th>
                      </tr>
                      {
                        currentUserAnswersState.map(function(item,i){
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{item.email}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.correctAnswers}</td>
                              <td style={{fontFamily:'Open Sans' }}>{this.convertSecondsTimeStampToHumanTime(item.timestamp/1000)}</td>
                              <td style={{fontFamily:'Open Sans' }}>{i + 1}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.rewardSent || "NONE"}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.code || "NONE"}</td>
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: !this.state.showStats ? 'block' : 'none'}}>
                  <div className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}>
                    <span className="fa fa-arrow-circle-down"/> Download Participants
                  </div>
                  <div className="admin-grid-container three-columns" style={{marginTop:20}}>
                    <div className="card card-styles text-xs-center">
                        <div className="card-body">
                            <blockquote className="card-bodyquote">
                                <p>Current Game Players</p>
                                <footer className="value-text">{players_length}</footer>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center">
                        <div className="card-body">
                            <blockquote className="card-bodyquote">
                                <p>Fan Answers</p>
                                <footer className="value-text">{userAnswers.length}</footer>
                            </blockquote>
                        </div>
                    </div>
                    {/*<div className="card card-styles text-xs-center">
                        <div className="card-body">
                            <blockquote className="card-bodyquote">
                                <p>Cards Per Pack</p>
                                <footer className="value-text">{selectedGame.rewardsPerPack}</footer>
                            </blockquote>
                        </div>
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> The Basics</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Finish</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                        <div className="col-md-9">
                                          <input id="gameName" name="gameName" type="text" className="form-control" value={this.state.gameName} onChange={this.handleChange} placeholder="12/11 vs MonStars" />
                                        </div>
                                      </div>
                                        <div className="form-group row mb-3">
                                          <label className="col-md-3 col-form-label" htmlFor="name3"> Select Question</label>
                                          <div className="col-md-9">
                                            <select className="form-control" name="question_select" id="question_select" value={this.state.question_select} onChange={this.handleChange}>
                                              <option/>
                                              {
                                                this.state.questionsList.map(function(item,index){
                                                  return(
                                                    <option value={item.key} key={index}>{item.questionText}</option>
                                                  )
                                                })
                                              }
                                            </select>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="form-group mb-3">
                                <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                  {
                                    this.state.questionsAdded.map(function(item,index){
                                      return(
                                        <div key={index} className="form-group">
                                          <li>{item.questionText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.removeFromToQuestionAddArray(index)}></span></li>
                                        </div>
                                      )
                                    })
                                  }
                                </ol>
                            </div>
                            <div className="form-group row mb-3" align="center">
                              <div className="col-md-12">
                                <button className="btn btn-primary btn-admin" onClick={()=>this.addQuestionToLocalArray()}>Add Question</button>
                              </div>
                            </div>
                        </div>

                         <div className="tab-pane" id="second">
                            <div className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                      <Accordion style={{margin:10}}>
                                        {
                                          questionsAddedLength.map(function(item,index){
                                            var winningHeader = "You win!"
                                            var winningMessage = "Check your email for your prize!"
                                            var winningMissedHeader = "Nice Job!"
                                            var winningMissedMessage = "You got " + index.toString() + " correct! Unfortunately you didn't win a prize but try again soon!"
                                            var losingHeader = "Oh No!"
                                            var losingMessage = "Nice try but no prize this time!  Try again soon!"
                                            if(index > 0){
                                              winningMessage = "You got " + index.toString() + " correct! Check your email for your prize!"
                                            }
                                            if(this.state['winningWinnerHeader' + index.toString()] != undefined){
                                              winningHeader = this.state['winningWinnerHeader' + index.toString()]
                                            } else if (tenantVariables.defaultWinningHeader && tenantVariables.defaultWinningHeader.length > 0) {
                                              winningHeader = this.detectUseOfIndex(tenantVariables.defaultWinningHeader, index)
                                            }
                                            if(this.state['winningWinnerMessage' + index.toString()] != undefined){
                                              winningMessage = this.state['winningWinnerMessage' + index.toString()]
                                            } else if (tenantVariables.defaultWinningMessage && tenantVariables.defaultWinningMessage.length > 0) {
                                              winningMessage = this.detectUseOfIndex(tenantVariables.defaultWinningMessage, index)
                                            }
                                            if(this.state['winningMissedHeader' + index.toString()] != undefined){
                                              winningMissedHeader = this.state['winningMissedHeader' + index.toString()]
                                            } else if (tenantVariables.defaultWinningMissHeader && tenantVariables.defaultWinningMissHeader.length > 0) {
                                              winningMissedHeader = this.detectUseOfIndex(tenantVariables.defaultWinningMissHeader, index)
                                            }
                                            if(this.state['winningMissedMessage' + index.toString()] != undefined){
                                              winningMissedMessage = this.state['winningMissedMessage' + index.toString()]
                                            } else if (tenantVariables.defaultWinningMissMessage && tenantVariables.defaultWinningMissMessage.length > 0) {
                                              winningMissedMessage = this.detectUseOfIndex(tenantVariables.defaultWinningMissMessage, index)
                                            }
                                            if(this.state['losingHeader' + index.toString()] != undefined){
                                              losingHeader = this.state['losingHeader' + index.toString()]
                                            } else if (tenantVariables.defaultLosingHeader && tenantVariables.defaultLosingHeader.length > 0) {
                                              losingHeader = tenantVariables.defaultLosingHeader
                                            }
                                            if(this.state['losingMessage' + index.toString()] != undefined){
                                              losingMessage = this.state['losingMessage' + index.toString()]
                                            } else if (tenantVariables.defaultLosingMessage && tenantVariables.defaultLosingMessage.length > 0) {
                                              losingMessage = tenantVariables.defaultLosingMessage
                                            }
                                            return(
                                              <AccordionItem key={index} className="if-number-correct-item" title={`If a fan answers ${(index).toString()} correct`} expanded={item === 1}>
                                                <div className="form-group row mb-3" style={{display: index > 0 ? 'flex' : 'none' }}>
                                                    <label className="col-md-3 col-form-label" htmlFor={`ticket_select${(index).toString()}`}> Main Prize</label>
                                                    <div className="col-md-9">
                                                      <select className="form-control" name={`ticket_select${(index).toString()}`} id={`ticket_select${(index).toString()}`} value={this.state['ticket_select' + index.toString()]} onChange={this.handleChange}>
                                                        <option></option>
                                                        {
                                                          this.state.ticketList.map(function(item,index){
                                                            return(
                                                              <option value={item.key} key={index}>{item.rewardName}</option>
                                                            )
                                                          })
                                                        }
                                                      </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row mb-3" style={{display: index > 0 ? 'flex' : 'none' }}>
                                                    <label className="col-md-3 col-form-label" htmlFor={`rewardAmount${(index).toString()}`}> Main Prize Amount</label>
                                                    <div className="col-md-4">
                                                        <input id={`rewardAmount${(index).toString()}`} name={`rewardAmount${(index).toString()}`} type="number" className="form-control" value={this.state['rewardAmount' + index.toString()]} onChange={this.handleChange} placeholder="5" />
                                                    </div>
                                                    <center className="col-md-1 col-sm-12">
                                                        <input id={`codes${(index).toString()}`} name={`codes${(index).toString()}`} type="checkbox" checked={this.state['codes' + index.toString()]} onChange={this.handleChange} />
                                                    </center>
                                                    <label className="col-md-4 col-form-label" htmlFor={`codes${(index).toString()}`}>Add Codes</label>
                                                </div>
                                                <div className="form-group row mb-3" style={{display: this.state['codes' + index.toString()] ? 'flex' : 'none' }}>
                                                  <label htmlFor={`codesArray${(index).toString()}`} className="col-md-3 col-form-label">Enter Codes (code1,code2)</label>
                                                  <div className="col-md-9">
                                                    <textarea value={this.state['codesArray' + index.toString()]} className="form-control" name={`codesArray${(index).toString()}`} checked={this.state['codesArray' + index.toString()]} onChange={this.handleChange} placeholder="code1,code2,code3"/>
                                                  </div>
                                                </div>
                                                <div className="form-group row mb-3">
                                                    <label className="col-md-3 col-form-label" htmlFor={`ticket_select_all_else${(index).toString()}`}> All Else Prize</label>
                                                    <div className="col-md-9">
                                                      <select className="form-control" name={`ticket_select_all_else${(index).toString()}`} id={`ticket_select_all_else${(index).toString()}`} value={this.state['ticket_select_all_else' + index.toString()]} onChange={this.handleChange}>
                                                        <option></option>
                                                        {
                                                          this.state.ticketList.map(function(item,index){
                                                            return(
                                                              <option value={item.key} key={index}>{item.rewardName}</option>
                                                            )
                                                          })
                                                        }
                                                      </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row mb-3">
                                                  <label className="col-md-3 col-form-label" htmlFor={`textEditChanges${(index).toString()}`}>Advanced Text Changes</label>
                                                  <div className="col-md-9">
                                                      <input name={`textEditChanges${(index).toString()}`} type="checkbox" checked={this.state['textEditChanges' + index.toString()]} onChange={this.handleChange} />
                                                  </div>
                                                </div>
                                                <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + index.toString()] ? 'flex' : 'none' }}>
                                                  <label htmlFor={`winningWinnerHeader${(index).toString()}`} className="col-md-3 col-form-label">Winner Header</label>
                                                  <div className="col-md-9">
                                                    <input value={winningHeader} className="form-control" name={`winningWinnerHeader${(index).toString()}`} type="text" onChange={this.handleChange} defualt="Nothing... Sad"/>
                                                  </div>
                                                </div>
                                                <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + index.toString()] ? 'flex' : 'none' }}>
                                                  <label htmlFor={`winningWinnerMessage${(index).toString()}`} className="col-md-3 col-form-label">Winner Message</label>
                                                  <div className="col-md-9">
                                                    <input value={winningMessage} className="form-control" name={`winningWinnerMessage${(index).toString()}`} type="text" onChange={this.handleChange}/>
                                                  </div>
                                                </div>
                                                <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + index.toString()] && index > 0 ? 'flex' : 'none' }}>
                                                  <label htmlFor={`winningMissedHeader${(index).toString()}`} className="col-md-3 col-form-label">Winner But Not Prize Header</label>
                                                  <div className="col-md-9">
                                                    <input value={winningMissedHeader} className="form-control" name={`winningMissedHeader${(index).toString()}`} type="text" onChange={this.handleChange}/>
                                                  </div>
                                                </div>
                                                <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + index.toString()] && index > 0 ? 'flex' : 'none' }}>
                                                  <label htmlFor={`winningMissedMessage${(index).toString()}`} className="col-md-3 col-form-label">Winner But Not Prize Message</label>
                                                  <div className="col-md-9">
                                                    <input value={winningMissedMessage} className="form-control" name={`winningMissedMessage${(index).toString()}`} type="text" onChange={this.handleChange}/>
                                                  </div>
                                                </div>
                                                <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + index.toString()] && index === 0 ? 'flex' : 'none' }}>
                                                  <label htmlFor={`losingHeader${(index).toString()}`} className="col-md-3 col-form-label">Non Correct Header</label>
                                                  <div className="col-md-9">
                                                    <input value={losingHeader} className="form-control" name={`losingHeader${(index).toString()}`} type="text" onChange={this.handleChange}/>
                                                  </div>
                                                </div>
                                                <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + index.toString()] && index === 0 ? 'flex' : 'none' }}>
                                                  <label htmlFor={`losingMessage${(index).toString()}`} className="col-md-3 col-form-label">Non Correct Message</label>
                                                  <div className="col-md-9">
                                                    <input value={losingMessage} className="form-control" name={`losingMessage${(index).toString()}`} type="text" onChange={this.handleChange}/>
                                                  </div>
                                                </div>
                                                {/*<div className="form-group row mb-3">
                                                    <label className="col-md-3 col-form-label" htmlFor={`tier_message${(index).toString()}`}>Special Message</label>
                                                    <div className="col-md-9">
                                                      <input id={`tier_message${(index).toString()}`} name={`tier_message${(index).toString()}`} type="text" className="form-control" value={this.state["tier_message" + index.toString()]} onChange={this.handleChange} placeholder="Place a message here about this specific number correct or reward!"/>
                                                    </div>
                                                </div>*/}
                                              </AccordionItem>
                                            );
                                          }, this)
                                        }
                                      </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="third">
                            {/*<form id="otherForm" method="post" action="#" className="form-horizontal"></form>*/}
                              <div className="row">
                                  <div className="col-12">
                                      <div className="text-center">
                                          <h2 className="mt-0">
                                              <i className="mdi mdi-check-all"/>
                                          </h2>
                                          <h3 className="mt-0">Finish</h3>

                                          <div className="row form-group">
                                            <div className="col-md-12">
                                              <p>You are all set to create a game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                            </div>
                                          </div>

                                      </div>
                                  </div>
                              </div>

                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a>
                            </li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'third' ? 'none' : '' }}>Next</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>Create Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
          <Modal isOpen={this.state.timeModal} toggle={this.timeModalToggle} style={{width: '90%'}} id="myTimeModal">
              <div className="card">
                <center className="card-body">
                  <h4 className="header-title mb-3"> {this.state.timerStatus}</h4>
                  <span style={{fontSize: "30px"}}>{this.state.timeLeft}</span>
                  <div>
                    <p>DO NOT REFRESH OR QUIT THE BROWSER WHILE THE TIMER IS RUNNING</p>
                    <button className="btn btn-danger" onClick={() => this.cancelCountDown()}>CANCEL COUNTDOWN AND RESET</button>
                  </div>
                </center>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
