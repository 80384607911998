import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { base } from '../../base';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';

class AdminHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
          users: [],
          userEmailOptIn: {},
          loading: true,
          usersAnswers: [],
        };
    }

    downloadUsers(){
        let csv = 'Email\n';
        this.state.users.forEach(function(row) {
            csv += row.email;
            csv += "\n";
        });
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        let date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv"
        hiddenElement.download = "all_time_users_" + date_got;
        hiddenElement.click();
    }

    componentDidMount(){
      // this.userAnswersRef = base.bindToState(`u`, {
      //   context: this,
      //   state: 'usersAnswers',
      //   asArray: true
      // });
      this.totalUsersRef = base.bindToState(`users`, {
        context: this,
        state: 'users',
        asArray: true
      });
      // this.totalOptInRef = base.bindToState(`userEmailOptIn`, {
      //   context: this,
      //   state: 'userEmailOptIn',
      // });
      this.currentGameFormFilledRef = base.bindToState(`currentGameFormFilled`, {
        context: this,
        state: 'currentGameFormFilled',
        asArray: true
      });
      this.setState({
        loading: false
      })
    }

    componentWillUnmount() {
      // base.removeBinding(this.userAnswersRef);
      base.removeBinding(this.totalUsersRef);
      // base.removeBinding(this.totalOptInRef);
      base.removeBinding(this.currentGameFormFilledRef);
    }

    // pingAPI(){
    //   var from_who = "steve@thepirate.com"
    //   var subject = "Test Subject"
    //   var recipients = ["nlawson@sqwadapp.co"]
    //   var reward_link = "test@link.com"
    //   var header_image = "https://s3-us-west-2.amazonaws.com/pick6-admin/badges/WingsEmailHeader.jpg"
    //   var description = "This is the description text"
    //   var codes = ["code1"]
    //   var reward_name = "This is the reward Name text"
    //   var reward_image = "https://s3-us-west-2.amazonaws.com/pick6-admin/badges/WingsRiseTogetherTeeReward_EmailImage.png"
    //   var level_text = "This is the what level you are on text"
    //   var email_help_text = "This is the Email Help Text"
    //   var rules_regs_link = "testImae.com"
    //   var team_logo = "https://s3-us-west-2.amazonaws.com/pick6-admin/badges/TopLeftIconWings.png"
    //   var gameObject = {"from_who": from_who, "subject": subject, "recipients": recipients,
    //   "reward_link": reward_link, "header_image": header_image, "description": description,
    //   "codes": codes, "reward_name": reward_name, "reward_image": reward_image, "level_text":level_text,
    //   "email_help_text": email_help_text, "rules_regs_link": rules_regs_link, "team_logo": team_logo};
    //   this.Auth.fetch('http://localhost:5000/rewards/levels', {
    //     method: 'POST',
    //     headers: {
	  //        'Content-Type': 'application/json',
    //        'IDToken': '8501c9f022fd9ca5ec68#{$}'
    //      },
    //     body: JSON.stringify(gameObject)
    //   }).then(response => {
    //     console.log(response)
    //   })
    // }

    render() {
      var userAnswers = 0;
      var totalUsers = 0;
      var totalEmailOptIns = 0;
      var userEmailOptIn = this.state.userEmailOptIn;
      totalEmailOptIns = Object.keys(userEmailOptIn).length;
      var users = this.state.users;
      var updatedUsers = [];
      var fan_side_link = process.env.REACT_APP_FAN_LINK || ""
      var scoreboard_horizontal_link = process.env.REACT_APP_SCOREBOARD_HORIZONTAL_LINK || ""
      var scoreboard_vertical_link = process.env.REACT_APP_SCOREBOARD_VERTICAL_LINK || ""
      if(users.length > 0){
        for(var user in users){
          var newUser = {};
          newUser.email = users[user].email;
          // newUser.optedIn = "FALSE"
          // var base64User = btoa(users[user].email);
          // var optedIntoEmails = userEmailOptIn[base64User];
          // if(optedIntoEmails){
          //   newUser.optedIn = "TRUE"
          // }
          updatedUsers.push(newUser);
        }
        totalUsers = users.length
      }
      // if(this.state.usersAnswers.length > 0){
      //   userAnswers = this.state.usersAnswers.length
      // }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <div className="top-button-styles">
                    <button className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}><span className="fa fa-arrow-circle-down"></span> Download Users</button>
                </div>
              {/*
              <button className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.pingAPI()}> PING API</button>
              */}
                <p className="admin-header-text" style={{marginTop:'10px'}}>Dashboard</p>
              <div className="admin-grid-container four-columns">
                <div className="card card-styles text-xs-center">
                    <div className="card-body">
                        <blockquote className="card-bodyquote">
                            <p>Total Trivia Users</p>
                            <footer className="value-text">{totalUsers}</footer>
                        </blockquote>
                    </div>
                </div>
                {/*
                <div className="card card-styles text-xs-center">
                    <div className="card-body">
                        <blockquote className="card-bodyquote">
                            <p>Total User Answers</p>
                            <footer className="value-text">{userAnswers}</footer>
                        </blockquote>
                    </div>
                </div>
                */}
                <div className="card card-styles text-xs-center" style={{display:'none'}}>
                    <div className="card-body">
                        <blockquote className="card-bodyquote">
                            <p>Total Email Opt Ins</p>
                            <footer className="value-text">{totalEmailOptIns}</footer>
                        </blockquote>
                    </div>
                </div>
              </div>
              <div className="row" style={{width:'100%'}}>
                <div className="col-md-12">
                  <p className="admin-header-text" style={{marginTop:'10px'}}>Game link: <span style={{fontSize:'20px'}}>{fan_side_link}</span></p>
                  <p className="admin-header-text" style={{marginTop:'10px'}}>Scoreboard Top Image Link: <span style={{fontSize:'20px'}}>{scoreboard_horizontal_link}</span></p>
                  <p className="admin-header-text" style={{marginTop:'10px'}}>Scoreboard Left Image link: <span style={{fontSize:'20px'}}>{scoreboard_vertical_link}</span></p>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default AdminHome
